<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{this.singular}}: {{this.data.firstName}} {{this.data.lastName}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('user','u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn :disabled="!this.data.getUser" @click="this.forceLogout">Force Logout</v-btn>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
      <v-row>
          <v-col>
            <v-text-field
                label="First Name"
                outlined
                v-model="data.firstName"
            />
            <v-text-field
                label="Last Name"
                outlined
                v-model="data.lastName"
            />
            <v-text-field
                label="Address"
                outlined
                v-model="data.address"
            />
            <v-text-field
                label="Default Print Node"
                outlined
                v-model="data.metadata.printNode"
            />
            <v-text-field
                label="Default Commission Rate (%)"
                outlined
                v-model="data.metadata.commission"
            />
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="data.dateOfBirth"
                    label="Date of Birth"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="data.dateOfBirth"
              />
            </v-menu>
            <v-select
                item-text="name"
                item-value="id"
                :items="userTypes"
                label="User Type"
                outlined
                v-model="data.userTypeId"
            />
            <v-autocomplete
                :items="branches"
                item-text="name"
                item-value="id"
                v-model="data.metadata.branchData.main"
                label="Main Branch"
                outlined
                clearable
                @change="updateRemBranches"
            />
            <v-autocomplete
                :items="remBranches"
                item-text="name"
                item-value="id"
                v-model="data.metadata.branchData.allowed"
                label="Allowed Branches"
                outlined
                chips
                deletable-chips
                multiple
            />
          </v-col>
          <v-col>
          <span v-if="this.isEditable">
            <v-chip
                v-for="device in registeredDevices" :key="device.id"
                class="ma-2"
            >
              {{device.name}} - {{device.id}}
            </v-chip>
          </span>
            <v-textarea
                label="Printers"
                outlined
                v-model="data.metadata.printers"
            />
            <v-text-field
                label="Phone"
                outlined
                v-model="data.phone"
            />
            <v-text-field
                label="Email"
                outlined
                v-model="data.email"
            />
            <v-text-field
                label="Username"
                :disabled="true"
                outlined
                v-model="data.username"
            />
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="data.hireDate"
                    label="Hire Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="data.hireDate"
              />
            </v-menu>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="data.fireDate"
                    label="Fire Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="data.fireDate"
              />
            </v-menu>
            <v-btn :disabled="!isEditable" v-if="isAllowed('user','updatePassword')" color='info' @click="openResetPassDialog">Reset Password</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn v-if="isEditable" @click="update">Save</v-btn>
            <v-btn v-if="isEditable && isAllowed('user','d')" style="margin-left: 10px;" class="error" @click="deleteData">Delete</v-btn>
          </v-col>
          <v-col>
            <v-textarea
                label="Secure ID Token"
                outlined
                v-model="data.metadata.sIdToken"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog
      v-model="resetPassDialog.open"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Reset This Passsword?
        </v-card-title>
        <v-card-text>
          <v-text-field
              label="Admin Password"
              outlined
              v-model="resetPassDialog.adminPass"
              type="password"
          />
          <v-text-field
              :label="'New Password for '+ this.data.firstName"
              outlined
              v-model="resetPassDialog.newPass"
          />
          <v-btn @click="resetSecureIdToken()" color="warning">Reset Secure ID Token</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeResetPassDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="resetPass()"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex"
  import axios from 'axios';
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: {metadata: {sIdToken: '', printers:'', branchData: {}}},
        singular: "User",
        singularLower: "user",
        plural: "Users",
        pluralLower: "users",
        isEditable: false,
        userTypes: [],
        branches: [],
        remBranches: [],
        lastBranch: null,
        resetPassDialog: {
          open: false,
          adminPass: '',
          newPass: '',
        },
        registeredDevices: [],
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error;

          if(!res.data.data.metadata){
            res.data.data.metadata = {sIdToken: '', branchData: {main:null,allowed:[]}}
          }
          if(res.data.data.dateOfBirth){
            res.data.data.dateOfBirth = new Date(res.data.data.dateOfBirth).toISOString().substring(0, 10);
          }
          if(res.data.data.hireDate){
            res.data.data.hireDate = new Date(res.data.data.hireDate).toISOString().substring(0, 10);
          }
          if(res.data.data.fireDate){
            res.data.data.fireDate = new Date(res.data.data.fireDate).toISOString().substring(0, 10);
          }
          if(!res.data.data.metadata.printers){
            res.data.data.metadata.printers = '{"allowed": "[]", "default": "[]"}'
          }else{
            res.data.data.metadata.printers = JSON.stringify(res.data.data.metadata.printers)
          }
          if(!res.data.data.metadata.branchData){
            res.data.data.metadata.branchData = {main:null,allowed:[]}
          }

          this.data = res.data.data;

          let registeredDevices = await axios.get(`${this.getEndpoint}/api/devices`)
          if(registeredDevices.data.error) throw registeredDevices.data.error
          this.registeredDevices = registeredDevices.data.data.devices

          let uts = await axios.get(`${this.getEndpoint}/api/usertypes`);
          if(uts.data.error) throw uts.data.error;
          this.userTypes = uts.data.data;

          let branches = await axios.get(`${this.getEndpoint}/api/branches`);
          if(branches.data.error) throw branches.data.error;
          this.branches = branches.data.data;

          this.remBranches = this.branches;
          this.updateRemBranches();

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getUser', 'getId', 'getEndpoint', 'isAllowed'])
    },
    methods: {
      ...mapMutations(['setUser']),
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async update(){
        try {
          this.loader = true;
          this.data.metadata.printers = JSON.parse(this.data.metadata.printers)
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack(`${this.singular} info Updated 🎉`, "success");
            this.isEditable = false;
            if(this.data.id===this.getId){
              this.setUser(this.data)

            }
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.data.metadata.printers = JSON.stringify(this.data.metadata.printers)
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)

          if(res.data.error) throw res.data.error

          this.snack(this.singular+" Deleted 🎉", "success");

          await this.$router.go(-1)
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error, "error");
        }
      },
      openResetPassDialog(){
        this.resetPassDialog.open = true;
      },
      closeResetPassDialog(){
        this.resetPassDialog.open = false;
        this.openResetPassDialog.adminPass = ''
        this.openResetPassDialog.newPass = ''
      },
      async resetPass(){
        try {
          let data = this.resetPassDialog
          data.adminId = this.getId
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/editPassword/${this.$route.params.id}`, data)
          if(res.data.error) throw res.data.error
          if(!res) throw "Error. Perhaps it is your password."

          if(res.data.success){
            this.snack("Password Updated", "success");
            this.closeResetPassDialog()
          }else throw "Password not updated"
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      //superadmin only
      async forceLogout(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/forceLogout/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack("User Logged Out 🎉", "success");
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async resetSecureIdToken(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/resetSecureIdToken/`, {uId: this.$route.params.id})
          if(res.data.error) throw res.data.error

          this.snack("SID Created", "success");
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      updateRemBranches(){
        if(this.data.metadata.branchData.main===null){
          this.remBranches = this.branches;
        }
        else{
          let arr = [];

          for(let branch of this.branches){
            let obj = {
              name: branch.name,
              id: branch.id,
              disabled: this.data.metadata.branchData.main===branch.id
            }

            arr.push(obj);
          }
          this.remBranches = arr;
        }

        if(this.lastBranch) this.data.metadata.branchData.allowed = this.data.metadata.branchData.allowed.filter(b => {return b!==this.lastBranch})
        if(this.data.metadata.branchData.main){
          if(this.data.metadata.branchData.allowed.indexOf(this.data.metadata.branchData.main)<0) this.data.metadata.branchData.allowed.push(this.data.metadata.branchData.main);
        }
        this.lastBranch = this.data.metadata.branchData.main;
      }
    }
  }
</script>
